<template>
  <div class="setting_form clear">
    <div class="white_box">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>White label</h2>
          </div>
        </div>
        <div class="box_content">
          <clip-loader v-if="loaders.loader" :color="'#0095f3'"></clip-loader>
          <form v-else class="basic_form clear">
            <div class="inline-box d-flex align-items-start">
              <div class="white_box inner_white_box pr-2 ">
                <div class="box_inner">
                  <div class="box_head d-flex align-items-center">
                    <div class="left">
                      <h2>Brand Details</h2>
                    </div>
                  </div>
                  <div class="box_content brand_box_content">
                    <div class=" input_field p-1">
                      <div class="w-82">
                        <div :class="{'input-field-error' : validations.name || validations.nameLength}"
                             class=" input_icon_text d-flex align-items-center profile__box">
                          <div class="content">
                            <div class="url">Brand</div>
                            <div class="name">
                              <div class="validation_input">
                                <input v-model="white_label_object.name" placeholder="Enter your brand"
                                       type="text" maxlength="100">
                              </div>
                            </div>
                          </div>
                        </div>
                        <span v-if="validations.name" class="input-error">{{ messages.name }}</span>
                        <span v-else-if="validations.nameLength" class="input-error">{{ messages.nameLength }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-10">
                        <div class=" input_field p-1">
                          <div class="w-100">
                            <div :class="{'input-field-error' : validations.logo || validations.logoLength}"
                                 class=" input_icon_text d-flex align-items-center profile__box">
                              <div class="content">
                                <div class="url">Brand Image URL</div>
                                <div class="name">
                                  <div class="validation_input">
                                    <input v-model="default_brand_image_url"
                                           placeholder="Enter the brand image url (200 X 200) or more"
                                           type="text">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <span v-if="validations.logo" class="input-error">{{ messages.logo }}</span>
                          <span v-else-if="validations.logoLength" class="input-error">{{ messages.logoLength }}</span>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="input_field">

                          <div class="profile_image"
                               :style="{ 'background': 'url(' + this.white_label_object.logo + ')' }">

                            <div class="input_file" v-if="!loaders.image_brand_loader">
                              <input @change="uploadBrandPicture" type="file" class="profile_input"
                                     id="profile_input">
                              <label for="profile_input">
                                <i v-tooltip.top-center="'Change Image'" class="far fa-camera"></i>
                              </label>

                              <!--to remove image-->
                              <input type="text" class="profile_input"
                                     @click="removeBrandPicture"
                                     id="remove_brand_image">
                              <label for="remove_brand_image" class="remove_image">
                                <i v-tooltip.top-center="'Remove Image'" class="far fa-close"></i>
                              </label>
                            </div>
                            <div class="input_file bg_opacity" v-else>
                              <clip-loader :size="size" :color="color"></clip-loader>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-10">
                        <div class=" input_field p-1">
                          <div class="w-100">
                            <div :class="{'input-field-error' : validations.favicon || validations.faviconLength}"
                                 class=" input_icon_text d-flex align-items-center profile__box">
                              <div class="content">
                                <div class="url">Favicon Image URL</div>
                                <div class="name">
                                  <div class="validation_input">
                                    <input v-model="default_favicon_image_url"
                                           placeholder="Enter the favicon image url (200 X 200) or more"
                                           type="text">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span v-if="validations.favicon" class="input-error">{{ messages.favicon }}</span>
                            <span v-if="validations.faviconLength"
                                  class="input-error">{{ messages.faviconLength }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="d-flex align-items-center">
                          <div class="input_field">
                            <div class="profile_image"
                                 :style="{ 'background': 'url(' + this.white_label_object.favicon + ')' }">

                              <div class="input_file" v-if="!loaders.image_favicon_loader">
                                <input @change="uploadFaviconPicture" type="file" class="profile_input"
                                       id="favicon_image">
                                <label for="favicon_image">
                                  <i v-tooltip.top-center="'Change Image'" class="far fa-camera"></i>
                                </label>

                                <!--to remove image-->
                                <input type="text" class="profile_input"
                                       @click="removeFaviconPicture"
                                       id="remove_favicon_image">
                                <label for="remove_favicon_image" class="remove_image">
                                  <i v-tooltip.top-center="'Remove Image'" class="far fa-close"></i>
                                </label>
                              </div>
                              <div class="input_file bg_opacity" v-else>
                                <clip-loader :size="size" :color="color"></clip-loader>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class=" input_field p-1">
                      <div class="w-82">
                        <div
                          :class="{'input-field-error' : validations.privacy_policy || validations.valid_privacy_policy || validations.privacy_policy_Length}"
                          class=" input_icon_text d-flex align-items-center profile__box">
                          <div class="content">
                            <div class="url">Privacy Policy URL</div>
                            <div class="name">
                              <div class="validation_input">
                                <input v-model="white_label_object.privacy_policy"
                                       placeholder="e.g. https://mywebsite.com"
                                       @blur="white_label_object.privacy_policy = focusoutConcatenation(white_label_object.privacy_policy)"
                                       type="text" maxlength="100">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span v-if="validations.privacy_policy" class="input-error">{{ messages.privacy_policy }}</span>
                      <span v-else-if="validations.privacy_policy_length"
                            class="input-error">{{ messages.privacy_policy_length }}</span>
                      <span v-else-if="validations.valid_privacy_policy"
                            class="input-error">{{ messages.valid_privacy_policy }}</span>
                    </div>

                    <div class=" input_field p-1">
                      <div class="w-82">
                        <div
                          :class="{'input-field-error' : validations.terms_of_service || validations.valid_terms_of_service || validations.terms_of_service_length}"
                          class=" input_icon_text d-flex align-items-center profile__box">
                          <div class="content">
                            <div class="url">Terms of Service URL</div>
                            <div class="name">
                              <div class="validation_input">
                                <input v-model="white_label_object.terms_of_service"
                                       placeholder="e.g. https://mywebsite.com"
                                       @blur="white_label_object.terms_of_service = focusoutConcatenation(white_label_object.terms_of_service)"
                                       type="text" maxlength="100">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span v-if="validations.terms_of_service" class="input-error">{{
                          messages.terms_of_service
                        }}</span>
                      <span v-else-if="validations.terms_of_service_length"
                            class="input-error">{{ messages.terms_of_service_length }}</span>
                      <span v-else-if="validations.valid_terms_of_service"
                            class="input-error">{{ messages.valid_terms_of_service }}</span>
                    </div>

                  </div>
                </div>
              </div>
              <div class="white_box inner_white_box pr-2 ">
                <div class="box_inner">
                  <div class="box_head d-flex align-items-center">
                    <div class="left">
                      <h2>Brand Domain Details</h2>
                    </div>
                  </div>
                  <div class="box_content" style="height: 533px">
                    <div class=" input_field p-1">
                      <div class="w-100">
                        <div
                          :class="{'input-field-error' : validations.domain || validations.valid_domain || validations.domain_length}"
                          class=" input_icon_text d-flex align-items-center profile__box">
                          <div class="content">
                            <div class="url">Domain
                              <div v-tooltip="domainStatusMessage(getSiteDetails.is_domain_connected)"
                                   class="campaign_status"
                                   :class="{'active_campaign':white_label_object.is_domain_connected,
                                   'disable_campaign': !white_label_object.is_domain_connected}"></div>
                              <span class="domain_connectivity" @click.prevent="verifyDomainRecord()">
                                <i v-tooltip="'Check Connectivity'" class="far fa-plug"
                                   v-if="!loaders.domain"
                                   aria-hidden="true"></i>
                                <clip-loader class="d-inline-block" :size="'16px'" :color="'#3988fe'"
                                             style="vertical-align: middle"
                                             v-tooltip="'Checking Connectivity...'"
                                             v-else></clip-loader>
                              </span>

                            </div>
                            <div class="name">
                              <div class="validation_input">
                                <input v-model="white_label_object.domain" placeholder="Enter your Domain"
                                       type="text" maxlength="100"
                                       @blur="white_label_object.domain = focusoutConcatenation(white_label_object.domain)"
                                >
                              </div>
                            </div>
                            <div class="dns_configuration">
                          <span>
                            * We recommend the following configuration that enables you to use variations of your branded domain.
                          </span>

                              <div class="table_container mt-1">
                                <table class="table list-content">
                                  <thead class=" ">
                                  <tr>
                                    <th></th>
                                    <th style="text-align: center;">Record type</th>
                                    <th style="text-align: center;">Hostname (or name)</th>
                                    <th style="text-align: center;">Points to (or address)</th>
                                    <th></th>

                                  </tr>
                                  </thead>

                                  <tbody class="list-content-body">

                                  <tr>
                                    <td>
                                      <div v-tooltip="domainStatusMessage(white_label_object.is_domain_connected)"
                                           class="campaign_status"
                                           :class="{'active_campaign':white_label_object.is_domain_connected,
                                   'disable_campaign': !white_label_object.is_domain_connected}"></div>
                                    </td>
                                    <td style="text-align: center;">CNAME</td>
                                    <td v-if="white_label_object.domain == ''" style="text-align: center;">* domain name
                                      *
                                    </td>
                                    <td v-else style="text-align: center;">
                                      {{ fetchSubdomainName(white_label_object.domain) }}
                                    </td>
                                    <td style="text-align: center;">whitelabel.replug.io</td>
                                    <td>
                                      <span class="domain_connectivity" @click.prevent="verifyDomainRecord()">
                                        <i v-tooltip="'Check Connectivity'" class="far fa-plug"
                                           v-if="!loaders.domain"
                                           aria-hidden="true"></i>
                                        <clip-loader class="d-inline-block" :size="'16px'" :color="'#3988fe'"
                                                     style="vertical-align: middle"
                                                     v-tooltip="'Checking Connectivity...'"
                                                     v-else></clip-loader>
                                      </span>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <div v-tooltip="domainStatusMessage(white_label_object.is_email_connected)"
                                           class="campaign_status"
                                           :class="{'active_campaign':white_label_object.is_email_domain_connected,
                                   'disable_campaign': !white_label_object.is_email_domain_connected}"></div>
                                    </td>
                                    <td style="text-align: center;">CNAME</td>
                                    <td style="text-align: center;">whitelabel-mail</td>
                                    <td style="text-align: center;">pm.mtasv.net</td>
                                    <td>
                                      <span class="domain_connectivity" @click.prevent="verifyEmailDomainRecord()">
                                        <i v-tooltip="'Check Connectivity'" class="far fa-plug"
                                           v-if="!loaders.email_domain"
                                           aria-hidden="true"></i>
                                        <clip-loader class="d-inline-block" :size="'16px'" :color="'#3988fe'"
                                                     style="vertical-align: middle"
                                                     v-tooltip="'Checking Connectivity...'"
                                                     v-else></clip-loader>
                                      </span>
                                    </td>
                                  </tr>

                                  </tbody>

                                </table>
                              </div>

                            </div>
                          </div>
                        </div>
                        <span v-if="validations.domain" class="input-error">{{ messages.domain }}</span>
                        <span v-else-if="validations.domain_length" class="input-error">{{
                            messages.domain_length
                          }}</span>
                        <span v-else-if="validations.valid_domain" class="input-error">{{
                            messages.valid_domain
                          }}</span>
                      </div>
                    </div>


                    <div style="margin-bottom: 125px"
                         class=" mt-3  input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">Custom Domain
                          <div v-if="getSiteDetails && getSiteDetails.custom_domain"
                               v-tooltip="domainStatusMessage(getSiteDetails.custom_domain.connectivity)"
                               class="campaign_status"
                               :class="{'active_campaign':
                     getSiteDetails.custom_domain.connectivity, 'disable_campaign': !getSiteDetails.custom_domain.connectivity}"></div>

                        </div>
                        <div class="name">
                          <div class="validation_input">
                            <b-dropdown right
                                        class="  mt-2 dropdown-menu-right default_style_dropdown "
                                        :no-caret="true"
                                        ref="bio_domains_dropdown_menu">
                              <div :class="{'input-field-error' : validations.custom_domain_id}"
                                   class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
                                   slot="button-content">
                                <template v-if="!select_domain">
                                  <span class="text">Select custom domain</span>
                                </template>
                                <template v-else>
                                  <span class="text">{{ select_domain }}</span>
                                </template>
                                <span class="arrow_icon ml-auto">
                                                    <i class=" fal fa-angle-down ml-3"></i>
                                                 </span>

                              </div>
                              <ul class="inner ">

                                <template v-if="getDomains.items.length > 0"
                                          v-for="domain in getDomains.items">

                                  <li @click.prevent="selectDomain(domain)" v-if="domain.connectivity"
                                      class="list_item_li">

                                    <div class="d-flex align-items-center profile__box">
                                      <div class="content">
                                        <div class="name">
                                          {{ trimUrl(domain.url) }}
                                        </div>

                                      </div>
                                    </div>

                                  </li>

                                </template>
                                <li class="no_text_li" v-if="getDomains.items.length === 0">
                                  <template>
                                    You have not connected any custom domain.
                                    <router-link :to="{name: 'custom_domains'}">Add
                                      custom
                                      domain.
                                    </router-link>
                                  </template>
                                </li>

                              </ul>
                            </b-dropdown>
                            <span v-if="validations.custom_domain_id" class="input-error">
                                    {{ messages.custom_domain_id }}
                                </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="white_box inner_white_box pr-2 mt-3" v-if="white_label_object.is_email_domain_connected">
              <div class="box_inner">
                <div class="box_head d-flex align-items-center">
                  <div class="left">
                    <h2>Brand Email Details</h2>
                  </div>
                </div>
                <div class="box_content">
                  <div class=" input_field p-1">
                    <div class="w-100">
                      <div :class="{'input-field-error' : validations.email || validations.emailLength}"
                           class=" input_icon_text d-flex align-items-center profile__box">
                        <div class="content">
                          <div class="url">Email</div>
                          <div class="name">
                            <div class="validation_input">
                              <input v-model="white_label_object.email" placeholder="Enter your email"
                                     type="text">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="validations.email" class="input-error">{{ messages.email }}</span>
                    <span v-else-if="validations.emailLength" class="input-error">{{ messages.emailLength }}</span>
                  </div>

                  <div class=" input_field p-1">
                    <div class="w-100">
                      <div
                        :class="{'input-field-error' : validations.reply_to_email || validations.reply_to_email_length}"
                        class=" input_icon_text d-flex align-items-center profile__box">
                        <div class="content">
                          <div class="url">Reply to Email</div>
                          <div class="name">
                            <div class="validation_input">
                              <input v-model="white_label_object.reply_to_email" placeholder="Enter your reply to email"
                                     type="text">
                            </div>
                          </div>
                        </div>
                      </div>
                      <span v-if="validations.reply_to_email" class="input-error">{{ messages.reply_to_email }}</span>
                      <span v-else-if="validations.reply_to_email_length"
                            class="input-error">{{ messages.reply_to_email_length }}</span>
                    </div>
                  </div>

                  <div class=" input_field p-1">
                    <div class="w-100">
                      <div
                        :class="{'input-field-error' : validations.email_sender_name || validations.email_sender_name_length}"
                        class=" input_icon_text d-flex align-items-center profile__box">
                        <div class="content">
                          <div class="url">Sender Name</div>
                          <div class="name">
                            <div class="validation_input">
                              <input v-model="white_label_object.email_sender_name" placeholder="Enter your sender name"
                                     type="text" maxlength="100">
                            </div>
                          </div>
                        </div>
                      </div>
                      <span v-if="validations.email_sender_name"
                            class="input-error">{{ messages.email_sender_name }}</span>
                      <span v-else-if="validations.email_sender_name_length"
                            class="input-error">{{ messages.email_sender_name_length }}</span>
                    </div>
                  </div>

                  <div class="dns_configuration" v-if="white_label_object.email_records">
                    <div class="table_container mt-1">
                      <table style="width: 100%;" class="table list-content">
                        <thead class=" ">
                        <tr>
                          <th style="text-align: center;width: 5%"></th>
                          <th style="text-align: center;width: 5%"></th>
                          <th style="text-align: center;width: 20%">HOSTNAME ( OR NAME )</th>
                          <th style="text-align: center;width: 15%">RECORD TYPE</th>
                          <th style="text-align: center;width: 30%">POINTS TO ( OR ADDRESS )</th>
                        </tr>
                        </thead>

                        <tbody class="list-content-body">

                        <tr>
                          <td style="text-align: center;">
                            <p>
                              <i class="fas fa-check" v-if="white_label_object.email_records.DKIMVerified"></i>
                              <i class="fas fa_times fa-times" v-else></i>
                            </p>
                          </td>
                          <td>
                            DKIM <br>
                            <span style="color: #19d152"
                                  v-if="white_label_object.email_records.DKIMVerified">Verfied</span>
                            <!--                            <span style="color: red" v-else>Not Verfied</span>-->
                            <button v-else
                                    @click.prevent="verifyEmailRecord()"
                                    :disabled="loaders.verify_email"
                                    class="btn---cta btn-blue with-shadow btn_verify">
                              <span>Verify</span>
                            </button>
                          </td>
                          <td style="text-align: center;word-break: break-all;"
                              v-if="white_label_object.email_records.DKIMHost != ''">
                            {{ white_label_object.email_records.DKIMHost }}
                          </td>
                          <td style="text-align: center;word-break: break-all;" v-else>
                            {{ white_label_object.email_records.DKIMPendingHost }}
                          </td>
                          <td style="text-align: center;">TXT</td>
                          <td style="text-align: center;word-break: break-all;"
                              v-if="white_label_object.email_records.DKIMTextValue!=''">
                            {{ white_label_object.email_records.DKIMTextValue }}
                          </td>
                          <td style="text-align: center;word-break: break-all;"
                              v-else>
                            {{ white_label_object.email_records.DKIMPendingTextValue }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: center;">
                            <p>
                              <i class="fas fa-check" v-if="white_label_object.email_records.SPFVerified"></i>
                              <i class="fas fa_times fa-times" v-else></i>
                            </p>
                          </td>
                          <td>
                            SPF <br>
                            <span style="color: #19d152"
                                  v-if="white_label_object.email_records.SPFVerified">Verfied</span>
                            <!--                            <span style="color: red" v-else>Not Verfied</span>-->
                            <button v-else
                                    @click.prevent="verifyEmailRecord()"
                                    :disabled="loaders.verify_email"
                                    class="btn---cta btn-blue with-shadow btn_verify">
                              <span>Verify</span>
                            </button>
                          </td>
                          <td style="text-align: center;">{{ white_label_object.email_records.SPFHost }}</td>
                          <td style="text-align: center;">TXT</td>
                          <td style="text-align: center;word-break: break-all;">
                            {{ white_label_object.email_records.SPFTextValue }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: center;">
                            <p>
                              <i class="fas fa-check"
                                 v-if="white_label_object.email_records.ReturnPathDomainVerified"></i>
                              <i class="fas fa_times fa-times" v-else></i>
                            </p>
                          </td>
                          <td>
                            DMARC<br>
                            <span style="color: #19d152"
                                  v-if="white_label_object.email_records.ReturnPathDomainVerified">Verfied</span>
                            <!--                            <span style="color: red" v-else>Not Verfied</span>-->
                            <button v-else
                                    @click.prevent="verifyEmailRecord()"
                                    :disabled="loaders.verify_email"
                                    class="btn---cta btn-blue with-shadow btn_verify">
                              <span>Verify</span>
                            </button>
                          </td>
                          <td style="text-align: center;">{{ white_label_object.email_records.ReturnPathDomain }}</td>
                          <td style="text-align: center;">CNAME</td>
                          <td style="text-align: center;word-break: break-all;">
                            {{ white_label_object.email_records.ReturnPathDomainCNAMEValue }}
                          </td>
                        </tr>
                        </tbody>

                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 text-center">

                <button @click.prevent="saveWhiteLabel()"
                        class="btn---cta btn-blue with-shadow btn-round btn-bold">
                  <span>Save</span>
                </button>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import http from '@/mixins/http-lib'
import {uploadImageURL} from '@/config/urls/helper/file'
import {getWhiteLabel, saveWhiteLabel} from '@/config/config'
import {verifyDomainRecords, verifyEmailDomainRecords, verifyEmailRecords} from '../../../../config/config'
import {mapActions} from 'vuex'
import {accountStateMessages} from "@/common/attributes";

export default {
  name: 'create-white-label',
  data () {
    return {
      white_label_object: {
        name: '',
        domain: '',
        email: '',
        privacy_policy: '',
        terms_of_service: '',
        logo: '',
        favicon: '',
        custom_domain_id: '',
        reply_to_email: '',
        email_sender_name: '',
        email_subdomain: '',
        is_domain_connected: false,
        is_email_domain_connected: false
      },
      loaders: {
        image_brand_loader: false,
        image_favicon_loader: false,
        loader: true,
        domain: false,
        email_domain: false,
        verify_email: false
      },
      default_brand_image_url: '',
      default_favicon_image_url: '',
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      messages: {
        name: 'Name is required.',
        nameLength: 'The name should not be greater than 35 characters.',
        domain: 'Please enter your domain',
        valid_domain: 'Please enter valid domain',
        domain_length: 'The domain url should not be greater than 74 characters.',
        email: 'Please enter your email',
        emailLength: 'The email should not be greater than 74 characters.',
        privacy_policy: 'Please enter your privacy policy URL',
        privacy_policy_length: 'The privacy url should not be greater than 74 characters.',
        terms_of_service: 'Please enter your terms of service URL',
        terms_of_service_length: 'The term of service url should not be greater than 74 characters.',
        logo: 'Please upload the logo',
        logoLength: 'The brand logo url should not be greater than 512 characters.',
        favicon: 'Please upload the favicon',
        faviconLength: 'The favicon url should not be greater than 512 characters.',
        custom_domain_id: 'Please select custom domain',
        reply_to_email: 'Please enter your email reply',
        reply_to_email_length: 'The email should not be greater than 74 characters',
        email_sender_name: 'Please enter your sender name',
        email_sender_name_length: 'The name should not be greater than 35 characters',
        valid_privacy_policy: 'Please enter valid URL',
        valid_terms_of_service: 'Please enter valid URL'
      },
      validations: {
        name: false,
        nameLength: false,
        domain: false,
        valid_domain: false,
        domain_length: false,
        email: false,
        emailLength: false,
        privacy_policy: false,
        privacy_policy_length: false,
        terms_of_service: false,
        logo: false,
        logoLength: false,
        favicon: false,
        faviconLength: false,
        custom_domain_id: false,
        reply_to_email: false,
        reply_to_email_length: false,
        email_sender_name: false,
        email_sender_name_length: false,
        valid_privacy_policy: false,
        valid_terms_of_service: false,
        terms_of_service_length: false
      },
      linkRegex: '',
      select_domain: ''
    }
  },
  async mounted () {
    this.white_label_object.logo = '/assets/img/profile-default.png'
    this.white_label_object.favicon = '/assets/img/profile-default.png'
    this.linkRegex = new RegExp('^(http|https)://', 'i')
    await this.getWhiteLabel()
    await this.fetchDomains()
  },
  methods: {
    ...mapActions(['fetchDomains']),
    async uploadBrandPicture (e) {
      this.loaders.image_brand_loader = true
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      let formData = new FormData()
      formData.append('input_file', files[0])
      await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            this.white_label_object.logo = res.data.resize_url
            this.default_brand_image_url = res.data.resize_url
            this.loaders.image_brand_loader = false
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {
      })
    },
    async uploadFaviconPicture (e) {
      this.loaders.image_favicon_loader = true
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      let formData = new FormData()
      formData.append('input_file', files[0])
      await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            this.white_label_object.favicon = res.data.resize_url
            this.default_favicon_image_url = res.data.resize_url
            this.loaders.image_favicon_loader = false
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {
      })
    },
    removeBrandPicture () {
      this.white_label_object.logo = '/assets/img/profile-default.png'
      this.default_brand_image_url = ''
    },
    removeFaviconPicture () {
      this.white_label_object.favicon = '/assets/img/profile-default.png'
      this.default_favicon_image_url = ''
    },
    saveWhiteLabel () {
      this.validations.name = this.requiredCheck(this.white_label_object.name)
      this.validations.domain = this.checkURL(this.white_label_object.domain)
      this.validations.email = this.checkEmail(this.white_label_object.email)

      this.validations.privacy_policy = this.requiredCheck(this.white_label_object.privacy_policy)
      this.validations.terms_of_service = this.requiredCheck(this.white_label_object.terms_of_service)

      this.validations.valid_privacy_policy = (!this.requiredCheck(this.white_label_object.privacy_policy) && !this.urlCheck(this.white_label_object.privacy_policy))
      this.validations.valid_terms_of_service = (!this.requiredCheck(this.white_label_object.terms_of_service) && !this.urlCheck(this.white_label_object.terms_of_service))

      this.validations.valid_domain = (!this.requiredCheck(this.white_label_object.domain) && !this.urlCheck(this.white_label_object.domain))

      this.validations.logo = this.checkURL(this.white_label_object.logo)
      this.validations.favicon = this.checkURL(this.white_label_object.favicon)
      this.validations.custom_domain_id = this.requiredCheck(this.white_label_object.custom_domain_id)
      this.validations.reply_to_email = this.requiredCheck(this.white_label_object.reply_to_email)
      this.validations.email_sender_name = this.requiredCheck(this.white_label_object.email_sender_name)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.white_label_object.email_subdomain = 'whitelabel-mail.' + this.getHostName(this.white_label_object.domain)
        this.postWhiteLabel()
      }
    },
    checkURL (value) {
      return (!value || !this.linkRegex.test(String(value.toLowerCase())))
    },
    async getWhiteLabel () {
      await http.post(getWhiteLabel, {}).then(res => {
          if (res.data.status) {
            this.white_label_object = {
              name: res.data.data.name,
              domain: res.data.data.domain,
              email: res.data.data.email,
              privacy_policy: res.data.data.privacy_policy,
              terms_of_service: res.data.data.terms_of_service,
              logo: res.data.data.logo,
              favicon: res.data.data.favicon,
              custom_domain_id: res.data.data.custom_domain_id,
              email_sender_name: res.data.data.email_sender_name,
              reply_to_email: res.data.data.reply_to_email,
              email_subdomain: res.data.data.email_subdomain,
              email_records: res.data.data.email_records,
              is_domain_connected: res.data.data.is_domain_connected,
              is_email_domain_connected: res.data.data.is_email_domain_connected
            }
            this.default_brand_image_url = res.data.data.logo
            this.default_favicon_image_url = res.data.data.favicon
            this.select_domain = res.data.data.custom_domain.url
            // this.getDomainName(this.white_label_object.custom_domain_id)
            this.loaders.loader = false
          } else {
            this.loaders.loader = false
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {
      })
    },
    async postWhiteLabel () {
      await http.post(saveWhiteLabel, this.white_label_object).then(res => {
          if (res.data.status) {
            this.loaders.loader = true
            this.getWhiteLabel()
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {
      })
    },

    /**
     * getting the domain messaged on the basis of status
     * @param status
     * @returns {string}
     */
    domainStatusMessage (status) {
      return status ? 'Your domain is verified and connected.' : 'Your domain is not verified.'
    },

    selectDomain (domain) {
      this.select_domain = this.trimUrl(domain.url)
      this.white_label_object.custom_domain_id = domain._id
      this.$refs.bio_domains_dropdown_menu.visible = false
    },
    getDomainName (domainId) {
      this.getDomains.items.forEach(item => {
        if (item._id === domainId) {
          this.select_domain = this.trimUrl(item.url)
        }
      })
    },
    verifyEmailRecord () {
      this.loaders.verify_email = true
      http.post(verifyEmailRecords, {}).then(resp => {
        if (resp.data.status) {
          this.getWhiteLabel()
          this.white_label_object.is_domain_connected = resp.data
          this.loaders.verify_email = false
        } else {
        }
      }).catch((err) => {
        console.log(err)
      })
    },

    /**
     * Verifing the domain records.
     */
    verifyDomainRecord () {
      this.loaders.domain = true


      http.post(verifyDomainRecords, {domain: this.white_label_object.domain}).then(res => {

        this.$store.dispatch('toastNotification', {
          message: res.data.message,
          type: res.data.status ? 'success' : 'error'
        })

        this.white_label_object.is_domain_connected = res.data.data
        this.loaders.domain = false

      }).catch((err) => {
        this.$store.dispatch('toastNotification', {
          message: err,
          type: 'error'
        })
      })
    },


    verifyEmailDomainRecord () {
      this.loaders.email_domain = true
      http.post(verifyEmailDomainRecords, {domain: this.white_label_object.domain}).then(res => {
        this.$store.dispatch('toastNotification', {
          message: res.data.message,
          type: res.data.status ? 'success' : 'error'
        })
        this.white_label_object.is_email_domain_connected = res.data.data
        this.loaders.email_domain = true
      }).catch((err) => {
        console.log(err)
      })
    },
    fetchSubdomainName (domain) {
      domain = domain.replace(/(^\w+:|^)\/\//, '')
      return domain.split('.').shift()
    }
  },
  watch: {
    'white_label_object.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 35)
      }
    },
    'default_brand_image_url' (value) {
      this.validations.logo = false
      this.validations.logoLength = !this.maxLength(value, 512)
    },
    'default_favicon_image_url' (value) {
      this.validations.favicon = false
      this.validations.faviconLength = !this.maxLength(value, 512)
    },
    'white_label_object.domain' (value) {
      if (value && value.length > 8) {
        this.validations.domain = false
        this.white_label_object.domain = this.urlConcatenation(this.white_label_object.domain)
        this.validations.domain_length = !this.maxLength(this.white_label_object.domain, 74)
        this.validations.valid_domain = !this.isValidURL(this.white_label_object.domain)
      }
    },
    'white_label_object.email_sender_name' (value) {
      if (value && value.length > 0) {
        this.validations.email_sender_name = false
        this.validations.email_sender_name_length = !this.maxLength(value, 35)
      }
    },
    'white_label_object.email' (value) {
      if (value && value.length > 0) {
        this.validations.email = false
        this.validations.emailLength = !this.maxLength(value, 74)
      }
    },
    'white_label_object.reply_to_email' (value) {
      if (value && value.length > 0) {
        this.validations.reply_to_email = false
        this.validations.reply_to_email_length = !this.maxLength(value, 74)
      }
    },
    'white_label_object.terms_of_service' (value) {
      if (value && value.length > 8) {
        this.validations.terms_of_service = false
        this.white_label_object.terms_of_service = this.urlConcatenation(this.white_label_object.terms_of_service)
        this.validations.terms_of_service_length = !this.maxLength(this.white_label_object.terms_of_service, 74)
        this.validations.valid_terms_of_service = !this.isValidURL(this.white_label_object.terms_of_service)
      }
    },
    'white_label_object.privacy_policy' (value) {
      if (value && value.length > 8) {
        this.validations.privacy_policy = false
        this.white_label_object.privacy_policy = this.urlConcatenation(this.white_label_object.privacy_policy)
        this.validations.privacy_policy_length = !this.maxLength(this.white_label_object.privacy_policy, 74)
        this.validations.valid_privacy_policy = !this.isValidURL(this.white_label_object.privacy_policy)
      }
    }
  }
}
</script>
<style scoped lang="less">
@import "../../../../assets/less/colors.less";

.remove_image {
  margin-left: 15px !important;

  i {
    color: red !important;
  }
}

.bg_opacity {
  background: rgba(0, 0, 0, 0.51) !important;
}

.profile_image {
  height: 66px !important;
  min-width: 46px !important;
  width: 69px !important;
  margin-top: 6px;
}

.input_icon_text {
  padding: 15px 15px;
}

.campaign_status {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 auto;
}

.active_campaign {
  background: @success;
  -webkit-box-shadow: 0 5px 7px 0 rgba(25, 209, 82, 0.3);
  -moz-box-shadow: 0 5px 7px 0 rgba(25, 209, 82, 0.3);
  box-shadow: 0 5px 7px 0 rgba(25, 209, 82, 0.3);
}

.disable_campaign {
  background: @orange;
  -webkit-box-shadow: 0 5px 7px 0 rgba(240, 205, 8, 0.3);
  -moz-box-shadow: 0 5px 7px 0 rgba(240, 205, 8, 0.3);
  box-shadow: 0 5px 7px 0 rgba(240, 205, 8, 0.3);
}

.dns_configuration {
  margin-top: 20px;

  span {
    font-size: 0.7rem;
    color: #1c2233;
  }

  .table_container {
    border-radius: 10px;
    border: solid 1px #d0d6e5;
    background: #f2f6f7;

    .table {
      background: #f2f6f7;
      border-radius: 10px;
      margin-bottom: 0.2rem;

      thead {
        border-radius: 10px;
        border-bottom: #d0d6e5 1px solid;

        tr {
          th {
            color: #676887;
          }
        }
      }
    }

    .list-content-body {
      background-color: #f2f6f7;
    }
  }
}

.inner_white_box {
  .box_inner {
    border: solid 1px #d0d6e5;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(162, 174, 209, 0.11);
  }

  .brand_box_content {
    .input_field {
      //margin-bottom: 18px;
      .w-82 {
        width: 82% !important;
      }
    }
  }

  .box_content {
    .input_field {
      //margin-bottom: 18px;
    }
  }
}

.fa-check {
  width: 30px;
  height: 29.9px;
  margin: 3px 10px 4px 0;
  padding: 10px 8.5px;
  border-radius: 50px;
  box-shadow: 0 5px 7px 0 rgba(25, 209, 82, 0.3);
  background-color: #19d152;
  color: #ffffff;
}

.fa_times {
  width: 30px;
  height: 29.9px;
  margin: 3px 10px 4px 0;
  padding: 9px 8.5px;
  border-radius: 50px;
  background-color: red;
  color: #ffffff;
}

.btn_verify {
  padding: 0 10px !important;
  min-width: 30px;

  span {
    color: #fff;
  }
}

.domain_connectivity {
  float: right;
  border-radius: 50%;
  text-align: center;
  line-height: 100%;
  height: 25px;
  width: 25px;
  color: #9B9B9B;
  display: inline-block;
  background: rgba(155, 155, 155, 0.11);
  border: 1px solid rgba(155, 155, 155, 0.13);
  margin-right: 0;
  background: #ffffff;
  -webkit-box-shadow: 0 2px 20px 0 rgb(162 174 209 / 11%);
  -moz-box-shadow: 0 2px 20px 0 rgba(162, 174, 209, 0.11);
  box-shadow: 0 2px 20px 0 rgb(162 174 209 / 11%);
  cursor: pointer;
  color: #1f216e;

  i {
    margin-top: 5px;
  }
}
</style>
